import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './components/Routes';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
      <AuthProvider>
        <Router />
      </AuthProvider>
  );
}

export default App;