import { useEffect } from "react";
import { fetchArtistData } from "../../store/reducers/artistSlice";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import '../../css/artist.css'
import { fetchArtifactArtist } from "../../store/reducers/artifactArtistSlice";
import { fetchArtifacts } from "../../store/reducers/artifactSlice";

const Test = () => {

    const params = useParams();
    const dispatch = useDispatch();

    
    
    
    const artist = useSelector((state) => state.artist.artistData.artistData);
    const artifactArtist = useSelector((state) => state.artifactArtist.artifactArtist)
    const artifacts = useSelector((state) => state.artifact.artifacts);

    let artistsArtifacts;
    let singleArtifact;
    let imagePath;

    if(artist){
        if(artifactArtist.length > 0 && artist.artistID){
            artistsArtifacts = artifactArtist.filter((artifact) => artifact.artistID.includes(parseInt(artist.artistID)))
            singleArtifact = artifacts.filter((artifact) => artifact.artifactID === artistsArtifacts[0].artifactID)
            if(singleArtifact[0]){
                imagePath = `${process.env.PUBLIC_URL}/images/${singleArtifact[0].images[0]}`;
            }
        }
    }


        useEffect(() => {
            const asyncFetchData = async () => {
                await dispatch(fetchArtistData(params.id));
                await dispatch(fetchArtifactArtist());
                await dispatch(fetchArtifacts())
            }
            asyncFetchData();
        }, [])


        console.log('protected route: ', artist)
    return (<div></div>)
}

export default Test;