import { useEffect, useState, useRef } from "react";
import ProgressBar from "./ProgressBar";
import '../../css/story.css';

const InnerStory = () => {
    const [scroll, setScroll] = useState(0);
    const scrollRef = useRef(null);

    useEffect(() => {
        const innerContainer = scrollRef.current;

        const handleScroll = () => {
            const scrollableHeight = innerContainer.scrollHeight - innerContainer.clientHeight;
            const scrollProgress = (innerContainer.scrollTop / scrollableHeight) * 80;
            setScroll(scrollProgress);
        };

        if (innerContainer) {
            innerContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (innerContainer) {
                innerContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const handleClick = (id) =>{
        const post = document.getElementById(id);
        post.scrollIntoView({behavior: 'smooth'});
    }

    return (
            <div className="story-inner-container" ref={scrollRef}>
                <ProgressBar progress={scroll} />
                <div className="icons-container">
                    <div onClick={() => handleClick(2)} className={scroll < 14.6 ? `icon1 icon not-active` : `icon1 icon`}/>
                    <div onClick={() => handleClick(3)} className={scroll < 29.3 ? `icon2 icon not-active` : `icon2 icon`}/>
                    <div onClick={() => handleClick(4)} className={scroll < 43.8 ? `icon3 icon not-active` : `icon3 icon`}/>
                    <div onClick={() => handleClick(5)} className={scroll < 58.5 ? `icon4 icon not-active` : `icon4 icon`}/>
                    <div onClick={() => handleClick(6)} className={scroll < 73.2 ? `icon5 icon not-active` : `icon5 icon`}/>
                </div>
                <div id="1" className="post1 story-post"/>
                <div id="2" className="post2 story-post"/>
                <div id="3" className="post3 story-post"/>
                <div id="4" className="post4 story-post"/>
                <div id="5" className="post5 story-post"/>
                <div id="6" className="post6 story-post"/>
            </div>
    );
    
}

export default InnerStory;
