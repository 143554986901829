import { Route, Routes } from "react-router-dom";

import Login from "./auth/Login";
import Home from "./artifact/Home";
import ArtistView from "./artists/ArtistView";
import Events from "./events/Events";
import Story from "./story/Story";
import Test from "./artists/Test";

const Router = () => {

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/artists/:id" element={<ArtistView/>} />
            <Route path="/artists/protected/:id" element={<Test/>} />
            <Route path="/events" element={<Events />} />
            <Route path="/story" element={<Story />} />
        </Routes>
    )
}

export default Router;