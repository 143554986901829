import '../../css/story.css';
import InnerStory from "./InnerStory";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchStory } from '../../store/reducers/storySlice';


const Story = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                await Promise.all([
                    dispatch(fetchStory())
                ]);
            }
            catch (e) {
                console.error(e);
            }
        };
        asyncFetchData();
    }
    , [dispatch]);


    return (
        <div className="story-container">
            <InnerStory />
        </div>
    );
};

export default Story;
